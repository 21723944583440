import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

export default function useAuthorsList() {
  const blankItem = {
    id: null,
    ful_name: null,
    email: [],
    phone: null,
    avatar: null,
    source_id: null,
    status_id: null,
    user_id: null,
    old_author: null,
    addresses: [],
    password: null,
    changePassword: true,
    compte_auteur: null,
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const downloadObject = ref({ loading: false, url: '' })
  const tableColumns = [
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
    {
      text: 'Auteur', value: 'author.full_name', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Compte auteur', value: 'author.compte_auteur', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Téléphone', value: 'author.phone', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Email', value: 'author.email', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Adresse', value: 'address', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Cpl', value: 'address2', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Département', value: 'department', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Code postal', value: 'postal_code', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Ville', value: 'city', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Pays',
      value: 'country.full_name',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'addresses.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'addresses.country_id',
      },
    },
    {
      text: 'Latitude', type: 'number', value: 'latitude', align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'addresses.latitude',
        is_condition: true,
        values: [
          { id: 0, name: 'Vide' },
        ],
      },
    },
    {
      text: 'Longitude', type: 'number', value: 'longitude',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'addresses.longitude',
        is_condition: true,
        values: [
          { id: 0, name: 'Vide' },
        ],
      },
    },

  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const filters = ref([])
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const optionsAddress = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const optionsOrdersAddress = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const authorTotalLocal = ref([])
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
      item.value.addresses = item.value.addresses || []
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  const fetchAddresses = async add => {
    const a = add

    const data = await axios.get('https://nominatim.openstreetmap.org?&format=jsonv2&q=75015')
    if (data && data.length) {
      a.latitude = parseFloat(data[0].latitude)
      a.longitude = parseFloat(data[0].longitude)
    }

    return a
  }

  const sleep = async milliseconds => {
    await new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const onSubmit = e => {
    loading.value = true
    store.dispatch('address/addItem', e)
      .then(response => {
        loading.value = false
      }).catch(error => {
        loading.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
          // errorMessages.value = { ...error.data.errors }
        }
      })
  }

  // fetch data
  const fetchItemsAddresses = () => {
    isFormActive.value = false
    downloadObject.value = { loading: true, url: null }
    store
      .dispatch('address/scrapeItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        orderBy: optionsAddress.value.sortBy ? optionsAddress.value.sortBy[0] : 'author.full_name',
        // eslint-disable-next-line no-nested-ternary
        direction: optionsAddress.value.sortDesc ? (optionsAddress.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: optionsAddress.value.page,
        per_page: optionsAddress.value.itemsPerPage,
      })
      .then(async response => {
        const {
          data,
          total,
          authorTotal,
        } = response.data

        console.log('data', response.data.data)
        if (response.data.data) {
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].latitude != null) {
              console.log('Now closing')
              // eslint-disable-next-line no-continue
              continue
            }
            const e = response.data.data[i]
            e.old_author = e.old_author

            // added because of google map
            const index = items.value.findIndex(er => er.id === e.id)

            if (index >= 0) {
              // items.value[index] = e
              Vue.set(items.value, index, e)
            }
            continue

            // add because of google map

            console.log('value1', e)
            axios.get('https://nominatim.openstreetmap.org', {
              params: {
                format: 'jsonv2',
                q: e.address,
              },
            }).then(mapResponse => {
              // console.log('map', mapResponse)
              // console.log('map-response', mapResponse.data)
              if (mapResponse && mapResponse.data && mapResponse.data.length) {
                const d = mapResponse.data.find(mp => mp.display_name.includes(e.postal_code) || mp.display_name.includes(e.address.toLowerCase()))
                try {
                  if (d && d !== null && d.lon && d.lat) {
                    console.log('data child', d)
                    e.latitude = parseFloat(d.lat)
                    e.longitude = parseFloat(d.lon)
                  } else {
                    e.latitude = parseFloat(mapResponse.data[0].lat)
                    e.longitude = parseFloat(mapResponse.data[0].lon)
                    console.log('data child index', mapResponse.data[0])
                  }
                } catch (e) {
                  e.latitude = parseFloat(mapResponse.data[0].lat)
                  e.longitude = parseFloat(mapResponse.data[0].lon)
                }

                const index = items.value.findIndex(er => er.id === e.id)

                if (index >= 0) {
                  // items.value[index] = e
                  Vue.set(items.value, index, e)
                }

                onSubmit(e)
              } else {
                axios.get('https://nominatim.openstreetmap.org', {
                  params: {
                    format: 'jsonv2',

                    /*   street: e.address, */
                    country: e.country ? e.country.name.trim() : null,
                    city: e.city,
                    postalcode: e.postal_code,
                  },
                }).then(mapResponse1 => {
                  // console.log('map', mapResponse)
                  // console.log('map-response', mapResponse.data)
                  if (mapResponse1 && mapResponse1.data && mapResponse1.data.length) {
                    const d1 = mapResponse1.data.find(mp => (e.country && mp.display_name.includes(e.country.name.trim().toLowerCase())) || mp.display_name.includes(e.postal_code))
                    if (d1 && d1 !== null && d1.lon && d1.lat) {
                      e.latitude = parseFloat(d1.lat)
                      e.longitude = parseFloat(d1.lon)
                      e.display_name = d1.display_name
                    } else {
                      e.latitude = parseFloat(mapResponse1.data[0].lat)
                      e.longitude = parseFloat(mapResponse1.data[0].lon)
                      e.display_name = mapResponse1.data[0].display_name
                    }

                    const index = items.value.findIndex(er => er.id === e.id)
                    if (index >= 0) {
                      // items.value[index] = e
                      Vue.set(items.value, index, e)
                    }
                    onSubmit(e)
                  }
                }).then(error => {
                  console.log('error1', error)
                })
              }
            }).then(error => {
              console.log('error', error)
            })
            console.log('2 secongs')
            await sleep(2000)
            console.log('1 secongs')
          }
          if (optionsAddress.value.page < response.data.last_page != null) {
            optionsAddress.value.page += 1
            fetchItemsAddresses()
          }
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchItemsOrdersAddresses = () => {
    isFormActive.value = false
    //downloadObject.value = { loading: true, url: null }
    store
      .dispatch('address/scrapeItemsOrders', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        orderBy: optionsOrdersAddress.value.sortBy ? optionsOrdersAddress.value.sortBy[0] : 'author.full_name',
        // eslint-disable-next-line no-nested-ternary
        direction: optionsOrdersAddress.value.sortDesc ? (optionsOrdersAddress.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: optionsOrdersAddress.value.page,
        per_page: optionsOrdersAddress.value.itemsPerPage,
        token: '4e8459c4-b7ed-446a-a77a-7f6f037f3d74'
      })
      .then(async response => {
        const {
          data,
          total,
          authorTotal,
        } = response.data

        console.log('data', response.data.data)
        if (response.data.data) {
          if (optionsOrdersAddress.value.page < response.data.last_page != null) {
            optionsOrdersAddress.value.page += 1
            fetchItemsOrdersAddresses()
          }
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchItems = download => {
    isFormActive.value = false
    downloadObject.value = { loading: true, url: null }
    store
      .dispatch('address/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        download,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'author.full_name',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const {
            data,
            total,
            authorTotal,
          } = response.data

          items.value = data.map(e => {
            e.old_author = e.old_author

            return e
          })
          store.commit('app-author/USERS', items.value)
          totalItems.value = total
          authorTotalLocal.value = authorTotal
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-author/deleteAuthor', item.value)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }

  const avatarText = value => {
    if (!value) return ''
    const nameArray = value.split(' ')

    return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
  }

  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      if (!loading.value) {
        loading.value = true
        selectedRows.value = []
        fetchItems()
      }
    }, 1500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAuthorRoleVariant = pay => {
    if (pay === 'subscriber') return 'primary'
    if (pay === 'author') return 'warning'
    if (pay === 'maintainer') return 'success'
    if (pay === 'editor') return 'info'
    if (pay === 'administrator') return 'error'

    return 'primary'
  }

  const resolveAuthorRoleIcon = pay => {
    if (pay === 'subscriber') return mdiAccountOutline
    if (pay === 'author') return mdiCogOutline
    if (pay === 'maintainer') return mdiDatabaseOutline
    if (pay === 'editor') return mdiPencilOutline
    if (pay === 'administrator') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveAuthorStatusName = status => {
    if (status === 0) return 'Inactif'
    if (status === 1) return 'Actif'

    return 'Actif'
  }

  const resolveAuthorStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'

    return 'primary'
  }

  const resolveAuthorTotalIcon = total => {
    if (total === 'Total Authors') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Authors') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Authors') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Authors') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    downloadObject,
    item,
    items,
    filters,
    tableColumns,
    searchQuery,
    payFilter,
    sourceFilter,
    statusFilter,
    totalItems,
    loading,
    options,
    authorTotalLocal,
    selectedRows,
    isFormActive,
    deleteDialog,
    confirmDelete,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    avatarText,
    resetForm,
    openDialog,
    deleteItem,
    fetchItems,
    resolveAuthorRoleVariant,
    resolveAuthorRoleIcon,
    resolveAuthorStatusVariant,
    resolveAuthorStatusName,
    resolveAuthorTotalIcon,
    fetchItemsAddresses,
    fetchItemsOrdersAddresses,
  }
}
