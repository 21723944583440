import {
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import axios from 'axios'
import Vue from 'vue'
import { icon, Icon, latLngBounds } from 'leaflet'
import store from '@/store'

import 'leaflet/dist/leaflet.css'
import { getItem } from '@core/utils'

const iconUrl = require('leaflet/dist/images/marker-icon.png')

const avatar = require('@/assets/images/avatars/maps_user_2.svg')
const avatarVente = require('@/assets/images/avatars/maps_paniers.svg')
const avatarLibrarie = require('@/assets/images/avatars/maps_livre.svg')

export default function controller() {
  const blankItem = {
    id: null,
    ful_name: null,
    email: [],
    phone: null,
    avatar: null,
    source_id: null,
    status_id: null,
    user_id: null,
    old_author: null,
    events: [],
    password: null,
    changePassword: true,
    compte_auteur: null,
  }
  const form = ref(null)
  const items = ref([])
  const itemsAcheteur = ref([])
  const itemsLibraries = ref([])
  const item = ref({})
  const downloadObject = ref({ loading: false, url: '' })
  const downloadObjectAcheteur = ref({ loading: false, url: '' })
  const downloadObjectLibraries = ref({ loading: false, url: '' })
  const tableColumns = [
  /*  {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    }, */
    {
      text: 'Auteur', value: 'author.full_name', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Compte auteur', value: 'author.compte_auteur', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Téléphone', value: 'author.phone', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Email', value: 'author.email', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Adresse', value: 'address', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Cpl', value: 'address2', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Département', value: 'department', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Code postal', value: 'postal_code', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Ville', value: 'city', filter: { place_holder: '', type: 'text' }, align: 'center',
    },

    {
      text: 'Pays',
      value: 'country.full_name',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'events.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'events.country_id',
      },
    },
    {
      text: 'Qualité', value: 'qualities', sortable: false,
    },

    /* {
      text: 'Latitude', type: 'number', value: 'latitude', filter: { place_holder: '', type: 'number' }, align: 'center',
    },
    {
      text: 'Longitude', type: 'number', value: 'longitude', filter: { place_holder: '', type: 'number' }, align: 'center',
    }, */
  ]
  const tableColumnsAcheteur = [
  /*  {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    }, */
    {
      text: 'Auteur', value: 'author.full_name', align: 'center', filter: { place_holder: '', type: 'text' },
    },

    {
      text: 'Observation',
      value: 'observation',
      align: 'center',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Observation" ?',
      fields: [
        {
          type: 'text', label: 'Observation', value: 'observation', required: false,
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'observation',
        text: 'Observation',
        place_holder: 'Observation',
        type: 'text',
      },
    },
    {
      text: 'Téléphone', value: 'phone', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Email', value: 'email', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Adresse', value: 'address', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Cpl', value: 'address_line2', filter: { place_holder: '', type: 'text' }, align: 'center',
    },

    /* {
      text: 'Département', value: 'department', filter: { place_holder: '', type: 'text' }, align: 'center',
    }, */
    {
      text: 'Code postal', value: 'postcode', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Ville', value: 'city', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Pays',
      value: 'country.full_name',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'events.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'events.country_id',
      },
    },

    /*  {
      text: 'Qualité', value: 'qualities', sortable: false
    }, */
    /* {
      text: 'Latitude', type: 'number', value: 'latitude', filter: { place_holder: '', type: 'number' }, align: 'center',
    },
    {
      text: 'Longitude', type: 'number', value: 'longitude', filter: { place_holder: '', type: 'number' }, align: 'center',
    }, */
  ]
  const tableColumnsLibraries = [
   /* {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },*/
    {
      text: 'Designation', value: 'name', editable: true, align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Siret', value: 'code', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Code insee', value: 'siret', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Adresse', value: 'address', align: 'center', filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Cpl', value: 'complement', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Commune', value: 'commune', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Code postal', value: 'postal_code', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Ville', value: 'city', filter: { place_holder: '', type: 'text' }, align: 'center',
    },
    {
      text: 'Pays',
      value: 'country.full_name',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'libraries.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'libraries.country_id',
      },
    },
    {
      text: 'Latitude', type: 'number', value: 'latitude', align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'libraries.latitude',
        is_condition: true,
        values: [
          { id: 0, name: 'Vide' },
        ],
      },
    },
    {
      text: 'Longitude', type: 'number', value: 'longitude',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'libraries.longitude',
        is_condition: true,
        values: [
          { id: 0, name: 'Vide' },
        ],
      },
    },

  ]
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const totalItems = ref(0)
  const totalItemsVentes = ref(0)
  const totalItemsLibraries = ref(0)
  const loading = ref(false)
  const loadingVente = ref(false)
  const loadingLibraries = ref(false)
  const filters = ref([])
  const filtersVentes = ref([])
  const filtersLibraries = ref([])
  const options = ref({
    rowsPerPage: 1000,
    itemsPerPage: 1000,
    page: 1,
    sortDesc: [true],
  })
  const optionsAcheteur = ref({
    rowsPerPage: 1000,
    itemsPerPage: 1000,
    page: 1,
    sortDesc: [true],
  })
  const optionsLibraries = ref({
    rowsPerPage: 1000,
    itemsPerPage: 1000,
    page: 1,
    sortDesc: [true],
  })
  const markers = ref([], /* [
    {
      id: 'm1',
      position: { lat: 48.8534951, lng: 2.3483915 },
      tooltip: 'tooltip for marker1',
      draggable: true,
      visible: true,
      avatar: 'http://localhost:8080/img/2.80504cd9.png',
      icon: icon({
        iconUrl: 'http://localhost:8080/img/2.80504cd9.png',
        iconSize: [30, 30],
        iconAnchor: [10, 15],
      }),
    },
    {
      id: 'm2',
      position: { lat: 45.9776398, lng: 1.1571078 },
      tooltip: 'tooltip for marker1',
      draggable: true,
      visible: true,
      avatar: 'http://localhost:8080/img/2.80504cd9.png',
      icon: icon({
        iconUrl: 'http://localhost:8080/img/2.80504cd9.png',
        iconSize: [30, 30],
        iconAnchor: [10, 15],
      }),
    },
    {
      id: 'm3',
      position: { lat: 46.7408455, lng: -1.3913095 },
      tooltip: 'tooltip for marker1',
      draggable: true,
      visible: true,
      avatar: 'http://localhost:8080/img/2.80504cd9.png',
      icon: icon({
        iconUrl: 'http://localhost:8080/img/2.80504cd9.png',
        iconSize: [30, 30],
        iconAnchor: [10, 15],
      }),
    },
  ] */)
  const bounds = ref(

    /* latLngBounds(
      { lat: 45.9776398, lng: 1.1571078 },
      { lat: 48.8534951, lng: 2.3483915 },
      { lat: 46.7408455, lng: -1.3913095 },
    ), */
  )
  const optionsAddress = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })

  const page = ref(1)
  const pageCount = ref(0)
  const pageVente = ref(1)
  const pageCountVente = ref(0)
  const zoom = ref(11)
  const center = ref({ lat: 48.8534951, lng: 2.3483915 })
  const address = ref(null)
  const latitude = ref(null)
  const longitude = ref(null)
  const distance = ref(100)
  const textQuality = ref([])
  const dataType = ref('all')
  const authorTotalLocal = ref([])
  const authorTotalLocalVentes = ref([])
  const authorTotalLocalLibraries = ref([])
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
      item.value.events = item.value.events || []
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  const fetchEvents = async add => {
    const a = add

    const data = await axios.get('https://nominatim.openstreetmap.org?&format=jsonv2&q=75015')
    if (data && data.length) {
      a.latitude = parseFloat(data[0].latitude)
      a.longitude = parseFloat(data[0].longitude)
    }

    return a
  }

  const sleep = async milliseconds => {
    await new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const onSubmit = e => {
    loading.value = true
    store.dispatch('event/addItem', e)
      .then(response => {
        loading.value = false
      }).catch(error => {
        loading.value = false
        console.log('error', error)
        if (error.data.errors) {
          // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
          // errorMessages.value = { ...error.data.errors }
        }
      })
  }

  // fetch data

  const fitPolyline = () => {
    const boundsLocal = latLngBounds(markers.value.map(o => o.position))
    bounds.value = boundsLocal
    console.log('bounds', bounds.value)
  }
  const fetchItems = download => {
    if (latitude.value == null || longitude.value == null) {
      loading.value = false

      return false
    }
    console.log('lat', latitude.value)
    console.log('lon', longitude.value)

    isFormActive.value = false
    downloadObject.value = { loading: true, url: null }
    loading.value = true
    store
      .dispatch('event/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        download,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'author.full_name',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
        latitude: latitude.value,
        longitude: longitude.value,
        distance: distance.value,
        textQuality: textQuality.value,
        dataType: dataType.value,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const {
            data,
            total,
            authorTotal,
          } = response.data

          items.value = data.filter(e => e.latitude != null && e.longitude != null).map(m => {
            m.old_author = m.old_author
            let quals = ''
            if (m.author && m.author.publications && m.author.publications.length) {
              quals = m.author.publications.map(e => store.state.app.textQualities.find(q => q.id === e.is_quality_text).label).join(', ')
            }
            m.qualities = quals

            return m
          })

          markers.value.push(...items.value.map(m => ({
            id: `${m.id}`,
            color: 'red',
            type: 'auteur',
            position: { lat: m.latitude, lng: m.longitude },
            tooltip: m.author ? `AUTEUR: ${m.author.full_name} - ${m.address}` : `${m.author_id}`,
            draggable: false,
            visible: true,
            label: m.author ? `${m.author.full_name}` : `${m.author_id}`,
            address: m.author ? `${m.address}` : '',
            email: m.author ? `${m.author.email}` : '',
            tel: m.author ? `${m.author.phone}` : '',
            avatar: m.author && m.author.avatar ? m.author.avatar : avatar,
            qualities: m.qualities,
            icon: icon({
              iconUrl: m.author && m.author.avatar ? m.author.avatar : avatar,
              iconSize: [30, 30],
              iconAnchor: [30, 30],
            }),
          })))
          markers.value.unshift(center.value.marker)
          fitPolyline()
          totalItems.value = total
          authorTotalLocal.value = authorTotal
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchItemsAcheteur = download => {
    if (latitude.value == null || longitude.value == null) {
      loading.value = false

      return false
    }
    console.log('lat', latitude.value)
    console.log('lon', longitude.value)

    isFormActive.value = false
    downloadObjectAcheteur.value = { loading: true, url: null }
    loading.value = true
    store
      .dispatch('event/fetchItemsAcheteur', {
        filters: filtersVentes.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        download,
        orderBy: optionsAcheteur.value.sortBy ? optionsAcheteur.value.sortBy[0] : 'author.full_name',
        // eslint-disable-next-line no-nested-ternary
        direction: optionsAcheteur.value.sortDesc ? (optionsAcheteur.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: optionsAcheteur.value.page,
        per_page: optionsAcheteur.value.itemsPerPage,
        latitude: latitude.value,
        longitude: longitude.value,
        distance: distance.value,
        textQuality: textQuality.value,
        dataType: dataType.value,
      })
      .then(response => {
        if (download) {
          downloadObjectAcheteur.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const {
            data,
            total,
            authorTotal,
          } = response.data

          itemsAcheteur.value = data.filter(e => e.latitude != null && e.longitude != null).map(m => {
            m.old_author = m.old_author
            let quals = ''
            if (m.author && m.author.publications && m.author.publications.length) {
              quals = m.author.publications.map(e => store.state.app.textQualities.find(q => q.id === e.is_quality_text).label).join(', ')
            }
            m.qualities = quals

            return m
          })

          markers.value.push(...itemsAcheteur.value.map(m => ({
            id: `${m.id}`,
            color: 'blue',
            type: 'vente',
            position: { lat: m.latitude, lng: m.longitude },
            tooltip: `VENTE: ${m.observation} - ${m.address}`,
            draggable: false,
            visible: true,
            label: `${m.observation}`,
            address: `${m.address}`,
            email: `${m.email}`,
            tel: `${m.phone}`,
            avatar: /* m.author && m.author.avatar ? m.author.avatar : */avatarVente,
            qualities: m.qualities,
            icon: icon({
              iconUrl: /* m.author && m.author.avatar ? m.author.avatar : */avatarVente,
              iconSize: [30, 30],
              iconAnchor: [30, 30],
            }),
          })))
          markers.value.unshift(center.value.marker)
          fitPolyline()
          totalItemsVentes.value = total
          authorTotalLocalVentes.value = authorTotal
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const fetchItemsLibraries = download => {
    if (latitude.value == null || longitude.value == null) {
      loading.value = false

      return false
    }
    console.log('lat', latitude.value)
    console.log('lon', longitude.value)

    isFormActive.value = false
    downloadObjectLibraries.value = { loading: true, url: null }
    loading.value = true
    console.log('filters', filtersLibraries)
    store
      .dispatch('event/fetchItemsLibraries', {
        filters: filtersLibraries.value.filter(ele => ele != null && ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        status: statusFilter.value,
        pay: payFilter.value,
        source: sourceFilter.value,
        download,
        orderBy: optionsLibraries.value.sortBy ? optionsLibraries.value.sortBy[0] : 'name',
        // eslint-disable-next-line no-nested-ternary
        direction: optionsLibraries.value.sortDesc ? (optionsLibraries.value.sortDesc[0] ? 'ASC' : 'DESC') : 'ASC',
        page: optionsLibraries.value.page,
        per_page: optionsLibraries.value.itemsPerPage,
        latitude: latitude.value,
        longitude: longitude.value,
        distance: distance.value,
        textQuality: textQuality.value,
        dataType: dataType.value,
      })
      .then(response => {
        if (download) {
          downloadObjectLibraries.value = {
            loading: false,
            url: response.data || null,
          }
        } else {

          const {
            data,
            total,
            authorTotal,
          } = response.data


          itemsLibraries.value = data
          totalItemsLibraries.value = total
          authorTotalLocalLibraries.value = authorTotal
          /*.filter(e => e.latitude != null && e.longitude != null).map(m => {
            m.old_author = m.old_author
            let quals = ''
            if (m.author && m.author.publications && m.author.publications.length) {
              quals = m.author.publications.map(e => store.state.app.textQualities.find(q => q.id === e.is_quality_text).label).join(', ')
            }
            m.qualities = quals

            return m
          })*/

          markers.value.push(...itemsLibraries.value.filter((e) => e != null && e.latitude != null && e.longitude != null ).map(m => ({
            id: `${m.id}`,
            color: 'blue',
            type: 'library',
            position: { lat: m.latitude, lng: m.longitude },
            tooltip: `LIBRAIRIE: ${m.name} - ${m.address}`,
            draggable: false,
            visible: true,
            label: `${m.name}`,
            address: `${m.address}`,
            postal_code: `${m.postal_code}`,
            commune: `${m.commune}`,
            city: `${m.city}`,
            code: `${m.code}`,
            avatar: avatarLibrarie,
            qualities: [],
            icon: icon({
              iconUrl: avatarLibrarie,
              iconSize: [30, 30],
              iconAnchor: [30, 30],
            }),
          })))
          if(center.value && center.value.marker) {
            markers.value.unshift(center.value.marker)
          }

          fitPolyline()

        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  const queryChanged = e => {
    console.log(e.target.value)
  }
  const addressChanged = e => {
    console.log('geomentry', { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() })
    console.log('address_components', e.address_components)
    console.log('formated_address', e.formatted_address)
    console.log('formated_address', e.formatted_address)
    console.log('name', e.name)
    console.log('place_id', e.place_id)
    console.log('vicinity', e.vicinity)

    console.log(e)

    /* addressChanged({lat: e.geometry.location.lat(), lng: e.geometry.location.lng() }) */
    /* emit('changed', {lat: e.geometry.location.lat(), lng: e.geometry.location.lng() }) */

    const c = {
      lat: e.geometry.location.lat(),
      lng: e.geometry.location.lng(),
      marker: {
        id: `${e.place_id}`,
        position: { lat: e.geometry.location.lat(), lng: e.geometry.location.lng() },
        tooltip: e.formatted_address,
        draggable: false,
        visible: true,
        label: e.name,
        address: e.formatted_address,
        email: '',
        tel: '',
        avatar: iconUrl,
      },
    }

    markers.value = [c.marker]
    latitude.value = e.geometry.location.lat()
    longitude.value = e.geometry.location.lng()
    center.value = c
    fitPolyline()
  }
  const distanceChanged = () => {
    // distance.value = value
    markers.value = []
    if (['all', 'auteurs'].includes(dataType.value) || dataType.value == null) {
      fetchItems()
    }
    if (['all', 'ventes'].includes(dataType.value) || dataType.value == null) {
      fetchItemsAcheteur()
    }
    if (['all', 'libraries'].includes(dataType.value) || dataType.value == null) {
      fetchItemsLibraries()
    }
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-author/deleteAuthor', item.value)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          fetchItemsAcheteur()
          fetchItemsLibraries()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }

  const avatarText = value => {
    if (!value) return ''
    const nameArray = value.split(' ')

    return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
  }

  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters, filtersVentes, filtersLibraries], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      if (!loading.value) {
        loading.value = true
        selectedRows.value = []
        markers.value = []
        if (['all', 'auteurs'].includes(dataType.value) || dataType.value == null) {
          fetchItems()
        }
        if (['all', 'ventes'].includes(dataType.value) || dataType.value == null) {
          fetchItemsAcheteur()
        }
        if (['all', 'libraries'].includes(dataType.value) || dataType.value == null) {
          fetchItemsLibraries()
        }
      }
    }, 1500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    markers.value = []
    if (['all', 'auteurs'].includes(dataType.value) || dataType.value == null) {
      fetchItems()
    }
  }, { deep: true })
  watch([markers], () => {
    console.log('changed markers', markers.value)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, optionsAcheteur], () => {
    loading.value = true
    selectedRows.value = []
    markers.value = []
    if (['all', 'ventes'].includes(dataType.value) || dataType.value == null) {
      fetchItemsAcheteur()
    }
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, optionsLibraries], () => {
    loading.value = true
    selectedRows.value = []
    markers.value = []
    if (['all', 'libraries'].includes(dataType.value) || dataType.value == null) {
      fetchItemsLibraries()
    }
  }, { deep: true })
  watch([markers], () => {
    console.log('changed markers', markers.value)
  }, { deep: true })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveAuthorRoleVariant = pay => {
    if (pay === 'subscriber') return 'primary'
    if (pay === 'author') return 'warning'
    if (pay === 'maintainer') return 'success'
    if (pay === 'editor') return 'info'
    if (pay === 'administrator') return 'error'

    return 'primary'
  }

  const resolveAuthorRoleIcon = pay => {
    if (pay === 'subscriber') return mdiAccountOutline
    if (pay === 'author') return mdiCogOutline
    if (pay === 'maintainer') return mdiDatabaseOutline
    if (pay === 'editor') return mdiPencilOutline
    if (pay === 'administrator') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveAuthorStatusName = status => {
    if (status === 0) return 'Inactif'
    if (status === 1) return 'Actif'

    return 'Actif'
  }

  const resolveAuthorStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'

    return 'primary'
  }

  const resolveAuthorTotalIcon = total => {
    if (total === 'Total Authors') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Authors') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Authors') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Authors') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    downloadObject,
    downloadObjectAcheteur,
    item,
    items,
    itemsAcheteur,
    markers,
    bounds,
    filters,
    filtersVentes,
    tableColumns,
    tableColumnsAcheteur,
    searchQuery,
    payFilter,
    sourceFilter,
    statusFilter,
    totalItems,
    totalItemsVentes,
    loading,
    loadingVente,
    options,
    optionsAcheteur,
    authorTotalLocal,
    authorTotalLocalVentes,
    selectedRows,
    isFormActive,
    deleteDialog,
    confirmDelete,
    blankItem,
    form,
    page,
    pageCount,
    pageVente,
    pageCountVente,
    snackBarMessage,
    isSnackbarBottomVisible,
    avatarText,
    resetForm,
    openDialog,
    deleteItem,
    fetchItems,
    fetchItemsAcheteur,
    resolveAuthorRoleVariant,
    resolveAuthorRoleIcon,
    resolveAuthorStatusVariant,
    resolveAuthorStatusName,
    resolveAuthorTotalIcon,
    addressChanged,
    queryChanged,
    address,
    distanceChanged,
    distance,
    textQuality,
    dataType,
    latitude,
    longitude,
    center,
    zoom,
    authorTotalLocalLibraries,
    optionsLibraries,
    loadingLibraries,
    totalItemsLibraries,
    itemsLibraries,
    tableColumnsLibraries,
    filtersLibraries,
    downloadObjectLibraries,
    fetchItemsLibraries,

  }
}
