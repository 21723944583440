import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import addressController from '@/views/apps/adresses/Controller'
import manuscriptController from '@/views/apps/manuscript/Controller'
import orderController from '@/views/apps/orders/Controller'
import authorController from '@/views/apps/author/author-list/useAuthorsList'
import publicationController from '@/views/apps/publications/Controller'

import sourceController from '@/views/apps/sources/Controller'
import statusController from '@/views/apps/status/Controller'
import tarriffController from '@/views/apps/tarifications/Controller'
import formatController from '@/views/apps/formats/Controller'
import genreController from '@/views/apps/genres/Controller'
import originController from '@/views/apps/origins/Controller'
import countryController from '@/views/apps/countries/Controller'
import colorController from '@/views/apps/colors/Controller'
import taskController from '@/views/apps/tasks/Controller'
import userController from '@/views/apps/user/user-list/useUsersList'
import roleController from '@/views/apps/role/Controller'
import communicationController from '@/views/apps/communications/Controller'
import isbnController from '@/views/apps/isbns/Controller'
import cartographieController from '@/views/apps/cartographies/Controller'

export default function controller() {
  const activityColumns = [
    {
      text: 'Utilisateur',
      value: 'user.name',
      filter: {
        place_holder: 'Nom ou email...', type: 'text', columns: ['users.name', 'users.email', 'users.code'], join_table: 'users', join_column: 'users.id', local_column: 'activities.user_id',
      },
    },
    {
      text: 'Action',
      value: 'action',
      filter: {
        place_holder: '', type: 'text',
      },
    },

    /* { text: 'Type', value: 'actionable_type' }, */
    {
      text: 'Description',
      value: 'description',
      filter: {
        place_holder: '', type: 'text',
      },
    },
    {
      text: 'Date',
      value: 'created_at',
      menu: false,
      filter: {
        place_holder: 'Date1 - Date2', type: 'date', menu: false, ref: 'menu', range: true,
      },
    }]
  const {
    tableColumns: manuscriptColumns,
  } = manuscriptController()
  const {
    tableColumns: orderColumns,
  } = orderController()
  const {
    tableColumns: authorColumns,
  } = authorController()
  const {
    tableColumns: addressColumns,
  } = addressController()


  const {
    tableColumns: publicationColumns,
    signatureTableColumnsForReport,
    collectTableColumnsForReport,
    textTableColumnsForReport,
    coverTableColumnsForReport,
    supervisionTableColumnsForReport,
    publicationTableColumnsForReport,
    paymentTableColumnsForReport,
    resiliationTableColumnsForReport,
    textTableColumns,
    coverTableColumns,
    supervisionTableColumns,
    publicationTableColumns,
    tableCommonColumns,
    paymentTableColumns,
    resiliationTableColumns,
    signatureTableColumns,
    collectTableColumns,
  } = publicationController()
  const report = [...tableCommonColumns,
    ...signatureTableColumnsForReport,
    ...collectTableColumnsForReport,
    ...textTableColumnsForReport,
    ...coverTableColumnsForReport,
    ...supervisionTableColumnsForReport,
    ...publicationTableColumnsForReport,
    ...paymentTableColumnsForReport,
    ...resiliationTableColumnsForReport]
  const { tableColumns: sourceColumns } = sourceController()
  const { tableColumns: statusColumns } = statusController()
  const { tableColumns: tariffColumns } = tarriffController()
  const { tableColumns: formatColumns } = formatController()
  const { tableColumns: genreColumns } = genreController()
  const { tableColumns: originColumns } = originController()
  const { tableColumns: countryColumns } = countryController()
  const { tableColumns: colorColumns } = colorController()
  const { filters: taskColumns } = taskController()
  const { filters: userColumns } = userController()
  const { filters: roleColumns } = roleController()
  const { filters: isbnColumns } = isbnController()
  const { tableColumns: cartographieColumns } = cartographieController()
  const { communicationTableColumns: communicationColumns } = communicationController()
  const tabs = [
    { tab: 'Manuscript', label: 'Manuscrits', columns: manuscriptColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Author', label: 'Auteurs', columns: authorColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Address', label: 'Adresses', columns: addressColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Cartographie', label: 'Cartographie', columns: cartographieColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Publication', label: 'Publications', columns: publicationColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Signature', label: 'Signatures', columns: signatureTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Collect', label: 'Collecte des éléments', columns: collectTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Text', label: 'Traitement texte', columns: textTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Cover', label: 'Traitement couverture', columns: coverTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Supervision', label: 'Supervision générale', columns: supervisionTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Parution', label: 'Parutions', columns: publicationTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Paiment', label: 'Paiements', columns: paymentTableColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Resiliation', label: 'Résiliation', columns: resiliationTableColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Report', label: 'Reporting', columns: report.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'isbns', label: 'ISBN Numeriques', columns: isbnColumns.value.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Order', label: 'Commandes', columns: orderColumns.map(ele => ({ value: ele.value, text: ele.text, permissions: ['view', 'edit'] })) },
    { tab: 'Communication', label: 'Communication', columns: communicationColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Activities', label: 'Activités', columns: activityColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Statuts', label: 'Statuts', columns: statusColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Sources', label: 'Sources', columns: sourceColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Tarifications', label: 'Tarifications', columns: tariffColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Formats', label: 'Formats', columns: formatColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Genres', label: 'Genres', columns: genreColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Origins', label: 'Origines', columns: originColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Countries', label: 'Pays', columns: countryColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Colors', label: 'Codes Couleurs', columns: colorColumns.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Tasks', label: 'Tâches', columns: taskColumns.value.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'Role', label: 'Rôles', columns: roleColumns.value.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: 'User', label: 'Utilisateurs', columns: userColumns.value.map(ele => ({ ...ele, permissions: ['view', 'edit'] })) },
    { tab: null, label: 'General', columns: [] },
  ]
  const types = [
    { value: 'see', text: 'Restreindre les données à l\'utilisateur' },
    { value: 'create', text: 'Créer' },
    { value: 'view', text: 'Voir' },
    { value: 'edit', text: 'Modifier' },
    { value: 'delete', text: 'Suprimer' },
  ]

  const blankItem = {
    id: null,
    name: null,
    display_name: null,
    permissions: [],
  }
  const form = ref(null)
  const items = ref([])
  const item = ref({})
  const filters = ref([
    /* { text: 'Code', value: 'name', filter: {type: 'text'} }, */
    { text: 'Nom', value: 'display_name', filter: { type: 'text' } },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const generateDialog = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  const openDialogGenerate = () => {
    generateDialog.value = !generateDialog.value
  }

  // fetch data
  const fetchItems = () => {
    isFormActive.value = false
    store
      .dispatch('permission/fetchItems', {
        filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })),
        term: searchQuery.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total } = response.data

        items.value = response.data
        totalItems.value = response.data.length

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      store.dispatch('permission/deleteItem', item.value.id)
        .then(response => {
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {

        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  return {
    types,
    tabs,
    item,
    items,
    filters,
    searchQuery,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    resetForm,
    deleteDialog,
    generateDialog,
    confirmDelete,
    openDialog,
    openDialogGenerate,
    deleteItem,
    fetchItems,
  }
}
